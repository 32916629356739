import { h } from "preact";
import { useState } from "preact/hooks";

import options from "../../shared/options";
import { getLanguageName, getColor } from "../../shared/utils";

const Option = ({ language, colors, onClick, active, url, style }) => {
  function handleClick(e) {
    e.preventDefault();
    onClick(language);
  }
  const [hover, setHover] = useState(false);
  const { full_name } = style;
  const languageName = getLanguageName(language);
  const name = full_name ? languageName : language.toUpperCase();
  const { textColor } = getColor(colors, { active, hover });

  return (
    <div
      data-l={language}
      tabIndex={-1}
      style={{ ...(!options.switcher_editor && { cursor: "pointer" }) }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className="language-option"
      {...(active
        ? {
            "aria-label": `Language selected: ${languageName}`,
          }
        : {
            role: "option",
          })}
      onClick={handleClick}>
      <a
        tabIndex={0}
        style={{
          color: textColor,
          opacity: active ? 1 : 0.4,
        }}
        href={url}
        target="_self"
        role="none"
        id={`weglot-language-${language}`}>
        {name}
      </a>
      <div
        className="underline"
        style={{ backgroundColor: textColor, width: active ? "100%" : "0%" }}
      />
    </div>
  );
};

export default Option;
